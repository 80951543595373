export const F2BCore = {
	Environment: {
		registerStatusListener: (listerner) => listerner(true),
		unregisterStatusListener: () => {},
		registerConnectionDateChangeListener: () => {},
		unregisterConnectionDateChangeListener: () => {},
	},
	Reporter: {
		registerChangeListener: () => {},
		removeChangeListener: () => {},
		getLeftReportsToUpload: () => 0,
	},
}

import PasswordLogin, { PasswordLoginData } from './loginMethods/passwordLogin';
import StudentIDLogin, { StudentIDLoginData } from './loginMethods/studentIDLogin';
import SSCLogin, { SSCLoginData } from './loginMethods/sscLogin';
export const Login = {
	LocalData: (...args) => {
		const self = this;
		Object.entries(([k, v]) => { sefl[k] = v; });
	},
	PasswordLogin, PasswordLoginData,
	StudentIDLogin, StudentIDLoginData,
	SSCLogin, SSCLoginData,
};
