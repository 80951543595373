import LoginMethod from '../loginMethod';
import LoginData from '../loginData';

export class SSCLoginData extends LoginData
{
	constructor(inputType){
		super();
		this._inputType = inputType;
	}

	get superSecretCode(){ return this._superSecretCode; }
	set superSecretCode(value){ this._superSecretCode = value; }
	get inputType(){ return this._inputType; }
	get isShowSuccessFailInfo(){ return this._showSuccessFailInfo; }
	set showSuccessFailInfo(value){ this._showSuccessFailInfo = value; }
	set firstName(value){ this._firstName = value; }
	set lastName(value){ this._lastName = value; }
	get firstName(){ return this._firstName; }
	get lastName(){ return this._lastName; }
}

export default class SSCLogin extends LoginMethod {
	populateRequestData(data){
		const { userData, superSecretCode, firstName, lastName } = this.sscLoginData;
		return {
			...super.populateRequestData(data),
			...userData.requestVariables,
			...Object.fromEntries(
				Object.entries({
					district_ssc: superSecretCode,
					first_name: firstName,
					last_name: lastName,
				}).filter(([_, v]) => v)
			),
		};
	}

	get environment(){ return this.sscLoginData.userData.environment; }
	get sscLoginData(){ return this.loginData; }
}
