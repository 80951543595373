import { F2BCore, Login } from '../lib/login';
import { useEffect, useRef } from 'react';
import { useSession } from '../lib/hooks';
const src = '/content/login_module/index.html';
const redirectToOauth = service => {
  if (!['classlink', 'clever', 'google'].includes(service)) {
    console.warn(`unsupported external login serice: ${service}`);
    return;
  }
  window.location = `${process.env.appHost}/${service}`;
};
const redirectTo = urlString => {
  const url = new URL(urlString);
  window.location = url.href.replace(url.origin, process.env.appHost);
};
const LoginPage = () => {
  const iframeRef = useRef();
  const {
    user,
    refresh
  } = useSession();
  useEffect(() => {
    window.F2BCore = F2BCore;
    window.Login = Login;
    const handler = ({
      data,
      origin,
      source
    }) => {
      if (window.origin !== origin) {
        return;
      }
      if (iframeRef.current?.contentWindow !== source) {
        return;
      }
      switch (data.event) {
        case 'SUCCESS_LOGIN':
          return refresh();
        case 'EXTERNAL_LOGIN':
          return redirectToOauth(data.page);
        case 'OPEN_PAGE':
          return redirectTo(data.page);
      }
    };
    window.addEventListener('message', handler);
    if (iframeRef.current) {
      iframeRef.current.src = src;
    }
    return () => {
      window.removeEventListener('message', handler);
      delete window.F2BCore;
      delete window.Login;
    };
  }, [iframeRef, refresh]);
  useEffect(() => {
    //redirect to dashboards root when it is able to handle any user
    //user && router.pathname && router.push({ pathname: '/' });
    if (user) {
      window.location = process.env.appHost;
    }
  }, [user]);
  return <iframe ref={iframeRef} allow="microphone; autoplay" style={{
    position: 'absolute',
    width: '100%',
    height: '100%',
    border: 0
  }} data-sentry-component="LoginPage" data-sentry-source-file="login.jsx" />;
};
LoginPage.subLayout = 'nonauthed';
export default LoginPage;