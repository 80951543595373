class UserData
{
	get name(){ return this._name; }
	set name(value){ this._name = value; }
	get email(){ return this._email; }
	set email(value){ this._email = value; }
	get lastName(){ return this._lastName; }
	set lastName(value){ this._lastName = value; }
	get studentID(){ return this._studentID?.split("|")[0]; }
	get environment(){ return this._environment; }
	set environment(value){ this._environment = value; }
	set studentID(value){ this._studentID = value; }
	get teacher(){ return this._teacher; }
	set teacher(value){ this._teacher = value; }
	get school(){ return this._school; }
	set school(value){ this._school = value; }
	get district(){ return this._district; }
	set district(value){ this._district = value; }
	get state(){ return this._state; }
	set state(value){ this._state = value; }
	get zip(){ return this._zip; }
	set zip(value){ this._zip = value; }

	get requestVariables(){
		return Object.fromEntries(
			Object.entries({
				first_name: this.name,
				last_name: this.lastName,
				student_id: this.studentID,
				teacher_name: this.teacher,
				school_name: this.school,
				district_name: this.district,
				state_name: this.state,
				zip: this.zip,
			}).filter(([_, v]) => v)
		);
	}
}

export default class LoginData
{
	constructor()
	{
		this._bookshelfId = -1;
		this._userData = new UserData();
	}

	get userData(){ return this._userData; }
	set userData(value){ this._userData = value; }

	get bookshelfId(){ return this._bookshelfId; }
	set bookshelfId(value){ this._bookshelfId = value; }
};
