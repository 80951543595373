import EventEmitter from 'eventemitter3';

class LoginInfoData {
	constructor(loginData){
		this.clear();
		this._loginData = loginData;
	}

	clear(){
		this._type = 'ONLINE';
		this._accountExpired = false;
		this._loginXML = null;
		this._enableStudentIDLogin = true;
		this._perfectMatch = true;
		this._studentExist = false;
		this._loginRight = true;
		this._intentRight = true;
	}

	get loginJson(){ return this._loginJson; }
	set loginJson(json){ this._loginJson = json; }
	get type(){ return this._type; }
	set type(value){ this._type = value; }
	get loginXML(){ return this._loginXML; }
	set loginXML(value){ this._loginXML = value; }
	get isAccountExpired(){ return this._accountExpired; }
	set accountExpired(value){ this._accountExpired = value; }
	get isEnableStudentIDLogin(){ return this._enableStudentIDLogin; }
	set enableStudentIDLogin(value){ this._enableStudentIDLogin = value; }
	get loginData() { return this._loginData; }
	get isStudentExist(){ return this._studentExist; }
	get isParent() { return (this._loginJson?.user?.role?._text) || (this._loginJson?.user_type === "Parent"); }
	set studentExist(value){ this._studentExist = value; }
	get isPerfectMatch(){ return this._perfectMatch; }
	set perfectMatch(value) { this._perfectMatch = value; }
	get loginRight(){ return this._loginRight; }
	set loginRight(value) { this._loginRight = value; }
	get loginRights(){ return this._loginRights; }
	get intentRight(){ return this._intentRight; }
	set intentRight(value){ this._intentRight = value; }
	get intentRights(){ return this._intentRights; }
};

export default class LoginMethod extends EventEmitter {
	constructor(loginData, districtID)
	{
		super();
		this._loginData = loginData;
		this._districtID = districtID;
		this._loginInfoData = new LoginInfoData(loginData);
	}
	get loginInfoData(){ return this._loginInfoData; }
	get loginData(){ return this._loginData; }

	populateRequestData(data){ return data ?? {} };

	login(){
		this.loginInfoData.clear();
		const requestData = this.populateRequestData();

		const performRequest = async () => {
			try {
				const resp = await window.fetch(
					`${process.env.appHost}/session.json`,
					{
						body: JSON.stringify(requestData),
						cache: 'no-store',
						credentials: 'include',
						headers: { 'Content-Type': 'application/json' },
						method: 'POST',
						redirect: 'error',
					},
				);
				if(![200, 401].includes(resp.status)){ throw 'unkown'; }

				const respBody = await resp.json();
				if(resp.status === 401){
					if(respBody.enable_student_id_login != null){ this.loginInfoData.enableStudentIDLogin = respBody.enable_student_id_login; }
					if(respBody.expired != null){ this.loginInfoData.accountExpired = respBody.expired; }
					if(respBody.perfect_match != null){ this.loginInfoData.perfectMatch = respBody.perfect_match; }
					if(respBody.student_id_exists_in_db != null){ this.loginInfoData.studentExist = respBody.student_id_exists_in_db; }
					throw respBody.error;
				}

				this.reportSuccessLogin();
			}
			catch(e){
				this.reportFailLogin();
			}
		};
		return performRequest();
	}
	reportSuccessLogin(){ this.emit('onSuccessLogin', this.loginInfoData); }
	reportFailLogin(){ this.emit('onFailLogin', this.loginInfoData); }
};
