import LoginMethod from '../loginMethod';
import LoginData from '../loginData';

export class StudentIDLoginData extends LoginData {
    get id(){ return this._id; }
    set id(value){ this._id = value; }
}

export default class StudentIDLogin extends LoginMethod {
    populateRequestData(data){
        return {
            ...super.populateRequestData(data),
            ...this.studentIDData.userData.requestVariables,
        }
    }

    get environment(){ return this.studentIDData.userData.environment; }
    get studentIDData(){ return this.loginData; }
}
