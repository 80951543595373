import LoginMethod from '../loginMethod';
import LoginData from '../loginData';

export class PasswordLoginData extends LoginData {
	get userPassword(){ return this._userPassword; }
	set userPassword(value){ this._userPassword = value; }

	get userName(){ return this._userName.split("|")[0]; }
	set userName(value){ this._userName = value; }

	get environment(){ return ''; }
};

export default class PasswordLogin extends LoginMethod {
	populateRequestData(data){
		const { email, userName, userPassword } = this.passwordData;
		const login = (email || userName);
		const password = userPassword;
		return {
			...super.populateRequestData(data),
			login, password,
		};
	}

	get passwordData(){ return this.loginData; }
	get environment(){ return this.passwordData.environment; }
}
